<template>
  <static-fullscreen-card>
    <template v-slot:header></template>
    <template v-slot:actions>
      <v-btn color="primary" @click="$refs.table.updateData()"
        ><btn-title icon="fas fa-sync-alt">Обновить</btn-title>
      </v-btn>
      <v-btn color="primary" @click="createNew()" title="Создать элемент"
        ><btn-title icon="far fa-plus-square">Создать</btn-title></v-btn
      ></template
    >
    <portal to="v-main">
      <GroupDialog
        v-model="showEditDialog"
        :id="idEdit"
        :api="url"
      />
    </portal>
    <a-table-f-api
      ref="table"
      :api="url"
      :model="model"
      :useQuery="true"
      :defaults="defaults"
      @click="onClickRow($event)"
    />
  </static-fullscreen-card>
</template>

<script>
export default {
  components: {
    GroupDialog: () => import("./dialogs/groupFileAccessDialog"),
  },
  data() {
    return {
      idEdit: 0,
      showEditDialog: false,
      title: "",
      m: this.$store.getters["config/get"].models.uploadsGroup,
      url: "/uploads-group",
      defaults: {
        sort: { key: "id", order: "ASC" },
      },
    };
  },
  created() {
    this.$root.title = "Группы доступа к файлам";
  },
  computed: {
    model() {
      let model = JSON.parse(JSON.stringify(this.m.list));
      model.forEach((el) => {
        if (el?.sourceList) {
          el.options = this.m[el.sourceList];
        }
      });
      return model;
    },
  },
  watch: {
    showEditDialog() {
      if (!this.showEditDialog) {
        this.$refs.table.updateData();
      }
    },
  },
  methods: {
    onClickRow(d) {
      this.showEditDialogFun(d.row.id);
    },
    createNew() {
      this.showEditDialogFun(0);
    },
    showEditDialogFun(id) {
      this.idEdit = id;
      this.showEditDialog = true;
    },
  },
};
</script>
